
const startAnimation = () => { 
    const anzeige = document.getElementById('anzeige_multistep');
    const anzeigePositionY = anzeige.offsetTop;
    const scrollTop = window.pageYOffset;
    const wHeight = window.innerHeight / 2
    const anzeigeFromTop = anzeigePositionY - scrollTop
    if (anzeigeFromTop < wHeight) {
        anzeige.classList.add('animated')
    }
}

if (document.getElementById('anzeige_multistep')) {
	window.addEventListener('scroll', startAnimation)	
}
